<template>
  <CardWrapper
    :tag="tag"
    class="card-image"
    :class="{ 'card-image--has-background': backgroundImage }"
  >
    <div class="card-image__images card-image__overlay">
      <div
        v-if="backgroundImage"
        class="card-image__image card-image__image--background"
      >
        <ImageWrapper
          v-bind="backgroundImage"
          :object-fit="
            backgroundImage.objectFit ? backgroundImage.objectFit : 'cover'
          "
        />
      </div>
      <div class="card-image__image card-image__image--foreground">
        <ImageWrapper
          v-bind="foregroundImage"
          :object-fit="
            foregroundImage.objectFit ? foregroundImage.objectFit : 'cover'
          "
        />
      </div>
    </div>
    <slot name="default" />
    <template #overlay>
      <slot name="overlay" />
    </template>
  </CardWrapper>
</template>

<script lang="ts" setup>
import { computed, defineComponent, defineProps, PropType } from 'vue'

import CardWrapper from '@base/components/CardWrapper/CardWrapper.vue'
import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'

type CardImageImage = {
  alt?: string
  fadeIn?: boolean
  lazy?: boolean
  longdesc?: string
  objectFit?: {
    type: string
    default: 'cover'
  }
  objectPosition?: string
  referrerpolicy?: string
  sizes?: string
  src?: string
  srcset?: string
  title?: string
}

defineComponent({
  name: 'CardImage',
  components: { CardWrapper, ImageWrapper }
})

const props = defineProps({
  tag: {
    type: String,
    default: 'div'
  },
  background: {
    type: Object as PropType<CardImageImage | undefined>,
    default: undefined
  },
  foreground: {
    type: Object as PropType<CardImageImage>,
    required: true
  }
})

const backgroundImage = computed(() => {
  if (!props.background) return

  return {
    ...props.background,
    height: '100%',
    width: '100%'
  }
})

const foregroundImage = computed(() => {
  return {
    ...props.foreground,
    height: '100%',
    width: '100%'
  }
})
</script>

<style lang="scss" scoped>
.card-image {
  &--has-background {
    &:hover {
      .card-image__image--foreground {
        opacity: 0;
        transition-timing-function: var(--animation-curve-enter);
      }
    }
  }

  &__overlay {
    &:after {
      background-image: linear-gradient(
        to top,
        rgba(45, 45, 45, 0.8) 20%,
        rgba(255, 255, 255, 0) 65%
      );
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }

  &__images {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &__image {
    height: 100%;
    width: 100%;

    &--background {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    &--foreground {
      transition: opacity var(--animation-speed-medium)
        var(--animation-curve-exit);
    }
  }
}
</style>
