<template>
  <CardListing :avatar="club.logo" class="card-listing-event">
    <template #aside>
      <ImageWrapper
        v-bind="featuredImageProps"
        class="card-listing-event__featured-image"
      />
    </template>
    <template #header>
      <div class="card-listing-event__heading">
        <USRatingStars
          v-if="club.rating > 0"
          :stars="5"
          :filled="club.rating"
          css-diameter="var(--stars-diameter)"
          class="card-listing-event__rating"
        />
        <div class="card-listing-event__metadata body--p1--regular">
          <p v-if="event.location" class="card-listing-event__location">
            {{ event.location }}
          </p>
          <p v-if="event.distance" class="card-listing-event__distance">
            {{ event.distance }}
          </p>
        </div>
        <p class="card-listing-event__club body--p1--regular">
          <router-link :to="club.to">
            {{ club.name }}
          </router-link>
        </p>
      </div>
      <h3 class="card-listing-event__event heading--h3">
        <router-link :to="event.to">
          {{ event.name }}
        </router-link>
      </h3>
    </template>
    <template #footer>
      <div class="card-listing-event__footer">
        <div class="card-listing-event__details">
          <div
            v-if="event.start"
            class="card-listing-event__detail card-listing-event__detail--date"
          >
            <p class="card-listing-event__label body--p1--regular">Date</p>
            <ClientOnly>
              <p class="card-listing-event__value heading--h3">
                {{ shortDate(event.start) }}
              </p>
            </ClientOnly>
          </div>
          <div
            v-if="event.startTime"
            class="card-listing-event__detail card-listing-event__detail--time"
          >
            <p class="card-listing-event__label body--p1--regular">Time</p>
            <ClientOnly>
              <p class="card-listing-event__value heading--h3">
                {{ event.startTime }}
              </p>
            </ClientOnly>
          </div>
          <div
            v-if="event.fee"
            class="card-listing-event__detail card-listing-event__detail--fee"
          >
            <p class="card-listing-event__label body--p1--regular">Price</p>
            <p class="card-listing-event__value heading--h3">
              {{ event.fee }}
            </p>
          </div>
        </div>
        <div class="card-listing-event__actions">
          <router-link :to="event.to">
            <USButton type="primary" label="View Details" />
          </router-link>
        </div>
      </div>
    </template>
    <template v-if="event.exclusive" #overlay>
      <div class="card-listing-event__ribbon">
        <UpSwingLogo
          class="card-listing-event__logo"
          object-fit="contain"
          mode="light-stacked"
        />
      </div>
    </template>
  </CardListing>
</template>

<script lang="ts" setup>
import { computed, defineComponent, defineProps, PropType } from 'vue'

import CardListing from '@base/components/CardListing/CardListing.vue'
import ClientOnly from '@base/components/ClientOnly/ClientOnly.vue'
import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'
import UpSwingLogo from '@base/components/UpSwingLogo/UpSwingLogo.vue'
import USButton from '@base/components/USButton/USButton.vue'
import USRatingStars from '@base/components/USRatingStars/USRatingStars.vue'

import { shortDate } from '@base/helpers/formatDate'

import {
  CardListingEventEventDetails,
  CardListingClubDetails
} from '@base/_types/Card'

defineComponent({
  name: 'CardListingEvent',
  components: {
    CardListing,
    ImageWrapper,
    UpSwingLogo,
    USButton,
    USRatingStars
  }
})

const props = defineProps({
  event: {
    type: Object as PropType<CardListingEventEventDetails>,
    required: true
  },
  club: {
    type: Object as PropType<CardListingClubDetails>,
    required: true
  }
})

const featuredImageProps = computed(() => {
  return {
    ...props.event.featuredImage,
    height: '100%',
    width: '100%',
    lazy: true,
    objectFit: 'cover'
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

$stars-diameter-mobile: 14px;
$stars-diameter-tablet: 20px;
$stars-diameter-desktop: 20px;

$ribbon-height-mobile: 60px;
$ribbon-width-mobile: 50px;

$ribbon-height-tablet: 84px;
$ribbon-width-tablet: 70px;

$ribbon-height-desktop: 84px;
$ribbon-width-desktop: 70px;

.card-listing-event {
  --stars-diameter: #{$stars-diameter-mobile};

  color: var(--font-color--primary-b2);

  @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
    --stars-diameter: #{$stars-diameter-tablet};
  }

  @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
    --stars-diameter: #{$stars-diameter-desktop};
  }

  &__featured-image {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &__heading {
    align-items: flex-start;
    display: flex;
    flex-flow: column wrap;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      align-items: center;
      flex-flow: row nowrap;
    }
  }

  &__rating {
    align-self: flex-end;
    flex: 0 0 auto;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      align-self: center;
      flex: 0 0 auto;
      margin-left: var(--spacing--7);
      order: 2;
      width: auto;
    }
  }

  &__metadata {
    display: flex;
    flex: 1 1 70%;
    flex-flow: row nowrap;
    font-weight: var(--font-weight--medium);
    justify-content: flex-end;
    margin-top: var(--spacing--8);
    text-transform: uppercase;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      margin-top: 0;
      order: 3;
    }
  }

  &__distance {
    margin-left: var(--spacing--7);
  }

  &__club {
    font-weight: var(--font-weight--medium);
    margin-top: var(--spacing--8);
    text-transform: uppercase;
    width: 100%;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      flex: 0 0 auto;
      margin-top: 0;
      order: 1;
      width: auto;
    }
  }

  &__event {
    color: var(--font-color--primary-b1);
    margin-top: var(--spacing--8);
    width: 100%;
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &__details {
    display: flex;
    flex-flow: row wrap;
    margin-right: var(--spacing--6);
  }

  &__detail {
    margin-bottom: var(--spacing--8);
    margin-right: var(--spacing--6);

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__label {
    font-weight: var(--font-weight--medium);
    margin-bottom: var(--spacing--8);
    text-transform: uppercase;
  }

  &__actions {
    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      align-items: flex-end;
      display: flex;
      flex-flow: row nowrap;
    }
  }

  &__ribbon {
    align-items: center;
    background: var(--color--secondary-c2);
    border-top-left-radius: var(--border-radius--small);
    border-top-right-radius: var(--border-radius--small);
    box-shadow: 5px 0px 20px #0000001a;
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 50% 70%, 0 100%, 0% 0%);
    display: flex;
    flex-flow: row nowrap;
    height: $ribbon-height-mobile;
    justify-content: center;
    padding-bottom: #{$ribbon-height-mobile * 0.3};
    padding-left: var(--spacing--8);
    padding-right: var(--spacing--8);
    padding-top: var(--spacing--8);
    position: absolute;
    right: var(--spacing--8);
    top: calc(var(--spacing--9) * -1);
    width: $ribbon-width-mobile;

    @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
      height: $ribbon-height-tablet;
      left: var(--spacing--8);
      padding-bottom: #{$ribbon-height-tablet * 0.3};
      right: auto;
      width: $ribbon-width-tablet;
    }

    @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
      height: $ribbon-height-desktop;
      padding-bottom: #{$ribbon-height-desktop * 0.3};
      width: $ribbon-width-desktop;
    }
  }

  &__logo {
    height: auto;
    width: 100%;
  }
}
</style>
