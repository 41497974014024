<template>
  <CardImage :foreground="image" class="card-event" :show-overlay="true">
    <template v-if="exclusive" #overlay>
      <div class="card-event__ribbon">
        <UpSwingLogo
          class="card-event__logo"
          object-fit="contain"
          mode="light-stacked"
        />
      </div>
    </template>
    <div class="card-event__content">
      <p class="card_event__date body--p1--medium">
        {{ dateAsNumbers(eventDate) }}
      </p>
      <div>
        <h3 class="card-event__name heading--h2">
          {{ eventName }}
        </h3>
        <p class="card-event__club body--p1--regular">
          {{ clubName }}
        </p>
      </div>
    </div>
  </CardImage>
</template>

<script lang="ts" setup>
import { computed, defineComponent, defineProps, PropType } from 'vue'

import CardImage from '@base/components/CardImage/CardImage.vue'
import UpSwingLogo from '@base/components/UpSwingLogo/UpSwingLogo.vue'

import { CardImage as CardImageType } from '@base/_types/Card'

import { dateAsNumbers } from '@base/helpers/formatDate'

defineComponent({
  name: 'CardEvent',
  components: { CardImage, UpSwingLogo }
})

const props = defineProps({
  eventName: {
    type: String,
    required: true
  },
  clubName: {
    type: String,
    required: true
  },
  eventDate: {
    type: Date,
    required: true
  },
  image: {
    type: Object as PropType<CardImageType>,
    required: true
  },
  exclusive: {
    type: Boolean,
    default: false
  }
})

const eventDateString = computed(() => {
  const { eventDate } = props

  let day = String(eventDate.getDate())
  let month = String(eventDate.getMonth() + 1)
  const year = String(eventDate.getFullYear())

  if (day.length < 2) day = `0${day}`

  if (month.length < 2) month = `0${month}`

  return `${day}/${month}/${year}`
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

$card-height-mobile: 280px;
$card-height-tablet: 350px;
$card-height-desktop: 480px;

$ribbon-height-mobile: 78px;
$ribbon-width-mobile: 65px;

$ribbon-height-tablet: 90px;
$ribbon-width-tablet: 75px;

$ribbon-height-desktop: 120px;
$ribbon-width-desktop: 100px;

.card-event {
  color: var(--font-color-neutral-0);
  height: $card-height-mobile;

  @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
    height: $card-height-tablet;
  }

  @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
    height: $card-height-desktop;
  }

  :deep(.card-wrapper__card) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
  }

  &__content {
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: space-between;
    padding: var(--spacing--6);
  }

  &__date {
    margin-bottom: var(--spacing--8);
  }

  &__name {
    font-family: var(--font-family--poppins);
    font-size: var(--font-size--z4);
    font-weight: var(--font-weight--semibold);
    letter-spacing: var(--letter-spacing--ls0);
    line-height: var(--line-height--default);
    margin-bottom: var(--spacing--8);
  }

  &__ribbon {
    align-items: center;
    background: var(--color--secondary-c2);
    border-top-left-radius: var(--border-radius--small);
    border-top-right-radius: var(--border-radius--small);
    box-shadow: 5px 0px 20px #0000001a;
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 50% 70%, 0 100%, 0% 0%);
    display: flex;
    flex-flow: row nowrap;
    height: $ribbon-height-mobile;
    justify-content: center;
    padding-bottom: #{$ribbon-height-mobile * 0.3};
    padding-left: var(--spacing--7);
    padding-right: var(--spacing--7);
    padding-top: var(--spacing--8);
    position: absolute;
    right: var(--spacing--8);
    top: calc(var(--spacing--9) * -1);
    width: $ribbon-width-mobile;

    @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
      height: $ribbon-height-tablet;
      padding-bottom: #{$ribbon-height-tablet * 0.3};
      width: $ribbon-width-tablet;
    }

    @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
      height: $ribbon-height-desktop;
      padding-bottom: #{$ribbon-height-desktop * 0.3};
      width: $ribbon-width-desktop;
    }
  }

  &__logo {
    height: auto;
    width: 100%;
  }
}
</style>
