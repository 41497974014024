<template>
  <CardWrapper :tag="tag" class="card-gallery">
    <div ref="glideEl" class="card-gallery__carousel glide">
      <div
        class="card-gallery__track glide__track"
        :class="{ 'card-gallery__track--overlay': showOverlay }"
        data-glide-el="track"
      >
        <ul class="glide__slides">
          <li v-for="(image, idx) in images" :key="idx" class="glide__slide">
            <ImageWrapper v-bind="generateImageWrapperProps(image)" />
          </li>
        </ul>
      </div>
      <div
        v-if="images.length > 1"
        class="card-gallery__bullets glide__bullets"
        data-glide-el="controls[nav]"
      >
        <button
          v-for="(_, idx) in images"
          :key="idx"
          class="card-gallery__bullet glide__bullet"
          :data-glide-dir="`=${idx}`"
        ></button>
      </div>
    </div>
    <slot name="default" />
    <template #overlay>
      <slot name="overlay" />
    </template>
  </CardWrapper>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  defineProps,
  onMounted,
  onUnmounted,
  PropType,
  ref
} from 'vue'
import Glide from '@glidejs/glide'

import CardWrapper from '@base/components/CardWrapper/CardWrapper.vue'
import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'

type CardGalleryImage = {
  alt?: string
  fadeIn?: boolean
  lazy?: boolean
  longdesc?: string
  objectPosition?: string
  referrerpolicy?: string
  sizes?: string
  src?: string
  srcset?: string
  title?: string
}

defineComponent({
  name: 'CardGallery',
  components: { CardWrapper, ImageWrapper }
})

const props = defineProps({
  tag: {
    type: String,
    default: 'div'
  },
  images: {
    type: Array as PropType<CardGalleryImage[]>,
    required: true
  },
  showOverlay: {
    type: Boolean,
    default: false
  }
})

const glideEl = ref<HTMLElement>()
let glideSlider

const generateImageWrapperProps = (
  image: CardGalleryImage
): { [key: string]: any } => {
  return {
    ...image,
    height: '100%',
    lazy: false,
    objectFit: 'cover',
    width: '100%'
  }
}

onMounted(() => {
  if (!glideEl.value) return

  glideSlider = new Glide(glideEl.value, {
    type: 'slide',
    startAt: 0,
    perView: 1,
    focusAt: 'center',
    gap: 0,
    autoplay: 6000,
    hoverpause: false,
    keyboard: false,
    swipeThreshold: 80,
    dragThreshold: 120,
    perTouch: 1,
    animationDuration: 650,
    rewind: true,
    rewindDuration: 1000,
    animationTimingFunc: 'ease-in-out'
  })
  glideSlider.mount()
})

onUnmounted(() => {
  if (!glideSlider) return

  glideSlider.destroy()
})
</script>

<style lang="scss" scoped>
$card-gallery-bullet-diameter: 10px;

.card-gallery {
  &__carousel {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &__track {
    height: 100%;

    &--overlay {
      &:after {
        background-image: linear-gradient(
          to top,
          rgba(45, 45, 45, 0.8) 20%,
          rgba(255, 255, 255, 0) 65%
        );
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        width: 100%;
      }
    }
  }

  .glide__slides {
    height: 100%;
  }

  .glide__slide {
    opacity: 0.8;
    transition-timing-function: var(--animation-curve-enter);

    &--active {
      opacity: 1;
      transition: opacity var(--animation-speed-fast)
        var(--animation-curve-exit);
      z-index: 1;
    }
  }

  &__bullets {
    bottom: var(--spacing--6);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    position: absolute;
    width: 100%;
  }

  &__bullet {
    backdrop-filter: blur(10px);
    background-color: rgba(white, 0.4);
    border-radius: 50%;
    height: $card-gallery-bullet-diameter;
    margin-right: $card-gallery-bullet-diameter;
    width: $card-gallery-bullet-diameter;

    &:last-child {
      margin-right: 0;
    }

    &.glide__bullet--active {
      background-color: #fff;
    }
  }
}
</style>
