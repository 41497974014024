<template>
  <component :is="tag" class="card-wrapper">
    <div class="card-wrapper__card border-radius--large">
      <slot name="default" />
    </div>
    <slot name="overlay" />
  </component>
</template>

<script lang="ts" setup>
import { defineComponent, defineProps } from 'vue'

defineComponent({
  name: 'CardWrapper'
})

defineProps({
  tag: {
    type: String,
    default: 'div'
  }
})
</script>

<style lang="scss" scoped>
.card-wrapper {
  &__card {
    background: var(--color--neutral-a5);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
}
</style>
