<template>
  <CardWrapper :tag="tag" class="card-video">
    <video
      ref="videoRef"
      class="card-video__video"
      :class="{ 'card-video__video--overlay': showOverlay }"
      :autoplay="videoObj.autoplay ? true : undefined"
      :controls="videoObj.controls ? true : undefined"
      :loop="videoObj.loop ? true : undefined"
      :muted="videoObj.muted ? true : undefined"
      :playsinline="videoObj.playsinline ? true : undefined"
      :poster="videoObj.poster"
      :preload="videoObj.preload"
      :src="videoObj.src && !videoObj.sources ? videoObj.src : undefined"
    >
      <template v-if="videoObj.sources && videoObj.sources.length">
        <source
          v-for="(source, index) in videoObj.sources"
          :key="index"
          :src="source.src"
          :type="source.type"
        />
      </template>
    </video>
    <div v-if="showOverlay" class="card-video__overlay"></div>
    <slot name="default" />
    <template #overlay>
      <slot name="overlay" />
    </template>
    <button
      class="card-video__fullscreen-button border-radius--round"
      @click="toggleFullscreen"
    >
      <USIcon
        name="play"
        color="var(--color--neutral-a5)"
        css-diameter="100%"
        class="card-video__play"
      />
    </button>
  </CardWrapper>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  defineProps,
  onMounted,
  onUnmounted,
  PropType,
  ref
} from 'vue'

import { VideoSource } from '@base/_types/Video'

import CardWrapper from '@base/components/CardWrapper/CardWrapper.vue'
import USIcon from '@base/components/USIcon/USIcon.vue'
import VideoWrapper from '@base/components/VideoWrapper/VideoWrapper.vue'

type CardVideoVideo = {
  objectPosition?: boolean
  poster?: string
  sources?: VideoSource[]
  src?: string
}

defineComponent({
  name: 'CardVideo',
  components: { CardWrapper, USIcon, VideoWrapper }
})

const props = defineProps({
  tag: {
    type: String,
    default: 'div'
  },
  video: {
    type: Object as PropType<CardVideoVideo>,
    required: true
  },
  showOverlay: {
    type: Boolean,
    default: false
  }
})

const videoRef = ref<HTMLVideoElement>()
let observer: IntersectionObserver | null = null

const videoObj = computed(() => {
  return {
    ...props.video,
    autoplay: true,
    controls: false,
    height: '100%',
    loop: true,
    muted: true,
    objectFit: 'cover',
    playsinline: true,
    preload: 'metadata',
    width: '100%'
  }
})

const toggleFullscreen = () => {
  if (videoRef.value) {
    const el = videoRef.value
    const requestFullscreen =
      el.requestFullscreen ||
      el.mozRequestFullscreen ||
      el.webkitRequestFullscreen ||
      el.msRequestFullscreen

    if (typeof requestFullscreen === 'function') {
      requestFullscreen.call(el)
    }
  }
}

onMounted(() => {
  if (!videoRef.value || observer) return

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: [0.3]
  }

  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.target !== videoRef.value) return

      const video = entry.target as HTMLVideoElement

      if (entry.isIntersecting) {
        video.play()
      } else {
        video.pause()
      }
    })
  }, observerOptions)

  observer.observe(videoRef.value)
})

onUnmounted(() => {
  if (!observer) return

  observer.disconnect()
  observer = null
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

$fullscreen-button-diameter-mobile: 48px;
$fullscreen-button-diameter-tablet: 64px;
$fullscreen-button-diameter-desktop: 90px;

.card-video {
  &:hover {
    .card-video__fullscreen-button {
      opacity: 1;
      transition-timing-function: var(--animation-curve-enter);
    }
  }

  &__video {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    &:not(:fullscreen) {
      object-fit: cover;
    }

    &:fullscreen {
      object-fit: contain;
    }

    &--overlay {
      &:after {
        background-image: linear-gradient(
          to top,
          rgba(45, 45, 45, 0.9),
          rgba(255, 255, 255, 0) 50%
        );
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        width: 100%;
      }
    }
  }

  &__overlay {
    background-image: linear-gradient(
      to top,
      rgba(45, 45, 45, 0.8) 20%,
      rgba(255, 255, 255, 0) 65%
    );
    bottom: 0;
    // content: '';
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
  }

  &__fullscreen-button {
    align-items: center;
    backdrop-filter: blur(10px);
    background-color: rgba(white, 0.4);
    display: flex;
    flex-flow: row nowrap;
    height: $fullscreen-button-diameter-mobile;
    justify-content: center;
    left: 50%;
    opacity: 0;
    padding: var(--spacing--6);
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: opacity var(--animation-speed-medium)
      var(--animation-curve-exit);
    width: $fullscreen-button-diameter-mobile;

    @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
      height: $fullscreen-button-diameter-tablet;
      width: $fullscreen-button-diameter-tablet;
    }

    @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
      height: $fullscreen-button-diameter-desktop;
      width: $fullscreen-button-diameter-desktop;
    }
  }

  &__play {
    margin-left: 4px;
  }
}
</style>
