import { RouteLocationRaw } from 'vue-router'

import { VideoSource } from '@base/_types/Video'

export enum CardType {
  CardEvent = 'CardEvent',
  CardHole = 'CardHole',
  CardListingCourse = 'CardListingCourse',
  CardListingEvent = 'CardListingEvent',
  CardResourceGallery = 'CardResourceGallery',
  CardResourceText = 'CardResourceText',
  CardResourceVideo = 'CardResourceVideo'
}

export type CardImage = {
  alt?: string
  fadeIn?: boolean
  lazy?: boolean
  longdesc?: string
  objectFit?: string
  objectPosition?: string
  referrerpolicy?: string
  sizes?: string
  src?: string
  srcset?: string
  title?: string
}

export type CardVideo = {
  objectPosition?: boolean
  poster?: string
  sources?: VideoSource[]
  src?: string
}

export type CardEvent = {
  eventName: string
  clubName: string
  eventDate: Date
  image: CardImage
  exclusive?: boolean
  to?: RouteLocationRaw
}

export type CardHole = {
  title: string
  par: number
  foreground: CardImage
  background?: CardImage
  showMenu?: boolean
  to?: RouteLocationRaw
}

export type CardListingImage = {
  src?: string
  srcset?: string
  sizes?: string
  alt?: string
  title?: string
}

export type CardListingCourseCourseDetails = {
  name: string
  holes: number
  par: number
  price: string
  featuredImage: CardListingImage
  location?: string
  distance?: string
  status?: string
  to: {
    course?: RouteLocationRaw
    teeTimes?: RouteLocationRaw
    editCourse?: RouteLocationRaw
    manageTeeTimes?: RouteLocationRaw
  }
}

export type CardListingClubDetails = {
  name: string
  rating: number
  logo: CardListingImage
  to: RouteLocationRaw
}

export type CardListingCourse = {
  course: CardListingCourseCourseDetails
  club: CardListingClubDetails
  to?: RouteLocationRaw
}

export type CardListingEventEventDetails = {
  name: string
  location: string
  distance: string
  fee: string
  start: Date
  exclusive: boolean
  featuredImage: CardListingImage
  to: RouteLocationRaw
}

export type CardListingEvent = {
  event: CardListingEventEventDetails
  club: CardListingClubDetails
  to?: RouteLocationRaw
}

export type CardResourceShare = {
  url: string
  title?: string
  text?: string
}

export type CardResourceImage = {
  alt?: string
  fadeIn?: boolean
  lazy?: boolean
  longdesc?: string
  objectPosition?: string
  referrerpolicy?: string
  sizes?: string
  src?: string
  srcset?: string
  title?: string
}

export type CardResourceGallery = {
  date: Date
  heading: string
  tags: string[]
  images: CardResourceImage[]
  share?: CardResourceShare
  showMenu?: boolean
  to?: RouteLocationRaw
}

export type CardResourceText = {
  date: Date
  text: string
  tags: string[]
  share?: CardResourceShare
  showMenu?: boolean
  to?: RouteLocationRaw
}

export type CardResourceVideo = {
  date: Date
  heading: string
  tags: string[]
  video: CardVideo
  share?: CardResourceShare
  showMenu?: boolean
  to?: RouteLocationRaw
}

export type Card = {
  type: CardType
  data:
    | CardEvent
    | CardHole
    | CardListingCourse
    | CardListingEvent
    | CardResourceGallery
    | CardResourceText
    | CardResourceVideo
  id?: string
}
