<template>
  <CardWrapper :tag="tag" class="card-listing">
    <div class="card-listing__aside">
      <div class="card-listing__aside-slot">
        <slot name="aside" />
      </div>

      <ImageWrapper class="card-listing__avatar" v-bind="avatarProps" />
    </div>
    <div class="card-listing__main">
      <div class="card-listing__header">
        <slot name="header" />
      </div>
      <div class="card-listing__footer">
        <slot name="footer" />
      </div>
    </div>
    <template #overlay>
      <slot name="overlay" />
    </template>
  </CardWrapper>
</template>

<script lang="ts" setup>
import { computed, defineComponent, defineProps, PropType } from 'vue'

import CardWrapper from '@base/components/CardWrapper/CardWrapper.vue'
import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'

import ImageClubLogoPlaceHolder from '@base/_assets/club-logo-placeholder.jpg'

type CardListingAvatar = {
  src?: string
  srcset?: string
  sizes?: string
  alt?: string
  title?: string
}

defineComponent({
  name: 'CardListing',
  components: { CardWrapper, ImageWrapper }
})

const props = defineProps({
  tag: {
    type: String,
    default: 'div'
  },
  avatar: {
    type: Object as PropType<CardListingAvatar>,
    required: true
  }
})

const avatarProps = computed(() => {
  let avatar = props.avatar

  if (!avatar.src) {
    avatar.src = ImageClubLogoPlaceHolder
    avatar.srcset = ImageClubLogoPlaceHolder
    avatar.alt = 'The upswing logo as a default placeholder for the club logo'
  }

  return {
    ...avatar,
    height: 'var(--avatar-diameter)',
    aspectRatio: '1',
    fadeIn: true,
    lazy: true,
    objectFit: 'contain',
    width: 'var(--avatar-diameter)'
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

$avatar-diameter-mobile: 60px;
$avatar-diameter-tablet: 73px;
$avatar-diameter-desktop: 80px;

.card-listing {
  --avatar-diameter: #{$avatar-diameter-mobile};

  width: 100%;

  @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
    --avatar-diameter: #{$avatar-diameter-tablet};
  }

  @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
    --avatar-diameter: #{$avatar-diameter-desktop};
  }

  :deep(.card-wrapper__card) {
    border: 1px solid var(--color--neutral-a4);

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      align-items: stretch;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }

  &__aside {
    max-height: 220px;
    width: 100%;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      max-height: none;
      width: 24%;
    }

    &:before {
      content: '';
      display: block;
      padding-top: calc(100% / var(--aspect-ratio--16-9));
      position: relative;
      width: 100%;
      z-index: -1;

      @include breakpoint.min-width(breakpoint.$breakpoint--9) {
        content: none;
      }
    }
  }

  &__aside-slot {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      position: relative;
    }
  }

  &__avatar {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    background-color: var(--color--neutral-a5);
    border-radius: 50%;
    bottom: calc(var(--avatar-diameter) / 2 * -1);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.29);
    height: var(--avatar-diameter);
    left: var(--spacing--6);
    overflow: hidden;
    position: absolute;
    width: var(--avatar-diameter);

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      bottom: auto;
      left: auto;
      right: calc(var(--avatar-diameter) / 2 * -1);
      top: var(--spacing--7);
    }
  }

  &__main {
    padding: var(--spacing--6);
    padding-top: calc(var(--avatar-diameter) / 2 + var(--spacing--8));
    width: 100%;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      height: 100%;
      padding: var(--spacing--6);
      padding-left: calc(var(--avatar-diameter) / 2 + var(--spacing--6));
      width: 76%;
    }
  }

  &__header {
    margin-bottom: var(--spacing--6);
  }
}
</style>
