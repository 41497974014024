<script lang="ts" setup>
import {
  computed,
  defineComponent,
  defineEmits,
  defineProps,
  PropType
} from 'vue'

import CardGallery from '@base/components/CardGallery/CardGallery.vue'
import ClientOnly from '@base/components/ClientOnly/ClientOnly.vue'
import TagPillStacked from '@base/components/TagPillStacked/TagPillStacked.vue'
import USButtonMenu from '@base/components/USButtonMenu/USButtonMenu.vue'
import USShareButton from '@base/components/USShareButton/USShareButton.vue'

import { dateAsNumbers } from '@base/helpers/formatDate'

import { CardResourceImage, CardResourceShare } from '@base/_types/Card'
import { ToastSemantic } from '@base/_types/Toast'

defineComponent({
  name: 'CardResourceVideo',
  components: {
    CardGallery,
    TagPillStacked,
    USButtonMenu,
    USShareButton
  }
})

const props = defineProps({
  date: {
    type: Date,
    default: undefined
  },
  heading: {
    type: String,
    required: true
  },
  tags: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  images: {
    type: Array as PropType<CardResourceImage[]>,
    required: true
  },
  share: {
    type: Object as PropType<CardResourceShare | undefined>,
    default: undefined
  },
  showMenu: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['edit', 'delete', 'shareSuccuss'])

const onClickOption = (e: string) => {
  switch (e) {
    case 'Edit':
      emit('edit')
      break
    case 'Delete':
      emit('delete')
      break
  }
}

const tagsProp = computed(() => {
  return props.tags.map((t, idx) => ({
    id: idx.toString(),
    label: t,
    name: t,
    value: t,
    canClick: true
  }))
})

const tagsVModel = computed(() => {
  return [...props.tags]
})
</script>

<template>
  <CardGallery
    :images="images"
    :show-overlay="true"
    class="card-resource-gallery"
  >
    <div class="card-resource-gallery__header">
      <TagPillStacked
        :model-value="tagsVModel"
        :tags="tagsProp"
        class="card-resource-gallery__tags"
      />
    </div>
    <USButtonMenu
      v-if="showMenu"
      :button-diameter="6"
      :dropdown-menu-items="[{ label: 'Edit' }, { label: 'Delete' }]"
      dropdown-menu-alignment="right"
      icon-color="var(--font-color--primary-b1)"
      class="card-resource-gallery__dropdown"
      @clickOption="onClickOption"
    />
    <div class="card-resource-gallery__content">
      <ClientOnly>
        <p
          v-if="date"
          class="card-resource-gallery__date body--p1--medium margin-bottom--8"
        >
          {{ dateAsNumbers(date) }}
        </p>
      </ClientOnly>

      <p class="card-resource-gallery__heading body--p1--regular">
        {{ heading }}
      </p>
    </div>
    <template v-if="share" #overlay>
      <USShareButton
        v-if="share"
        variation="icon-circle"
        icon="share"
        :share-url="share.url"
        :share-title="share.title || ''"
        :share-text="share.text || ''"
        class="card-resource-gallery__share-button"
        @succuss="$emit('shareSuccuss')"
      />
    </template>
  </CardGallery>
</template>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

$card-height-mobile: 360px;
$card-height-tablet: 412px;
$card-height-desktop: 516px;

.card-resource-gallery {
  height: $card-height-mobile;

  @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
    height: $card-height-tablet;
  }

  @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
    height: $card-height-desktop;
  }

  &:hover {
    .card-resource-gallery__share-button {
      opacity: 1;
      pointer-events: auto;
      transition-timing-function: var(--animation-curve-enter);
    }
  }

  :deep(.card-wrapper__card) {
    border: 1px solid var(--color--neutral-a4);
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding: var(--spacing--6);
  }

  &__dropdown {
    position: absolute;
    right: calc(var(--spacing--6) / 2);
    top: var(--spacing--6);
  }

  &__tags {
    :deep(.tag-pill-stacked__tags) {
      justify-content: flex-start;
    }
  }

  &__content {
    color: var(--font-color--neutral-a4);
    margin-bottom: var(--spacing--6);
  }

  &__date {
    margin-bottom: var(--spacing--8);
    text-transform: uppercase;
  }

  &__share-button {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: -16px;
    top: -16px;
    transition: opacity var(--animation-speed-fast) var(--animation-curve-exit);

    :deep(.us-button) {
      background-color: #fff;
    }
  }
}
</style>
