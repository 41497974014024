<template>
  <CardListing :avatar="club.logo" class="card-listing-course">
    <template #aside>
      <ImageWrapper
        v-bind="featuredImageProps"
        class="card-listing-course__featured-image"
      />
    </template>
    <template #header>
      <div class="card-listing-course__heading">
        <USRatingStars
          v-if="club.rating > 0"
          :stars="5"
          :filled="club.rating"
          css-diameter="var(--stars-diameter)"
          class="card-listing-course__rating"
        />
        <div
          v-if="course.location || course.distance || course.status"
          class="card-listing-course__metadata body--p1--regular"
        >
          <p
            v-if="course.location"
            class="card-listing-course__metadata-item card-listing-course__location"
          >
            {{ course.location }}
          </p>
          <p
            v-if="course.distance"
            class="card-listing-course__metadata-item card-listing-course__distance"
          >
            {{ course.distance }}
          </p>
          <p
            v-if="course.status"
            class="card-listing-course__metadata-item card-listing-course__status"
          >
            {{ course.status }}
          </p>
        </div>
        <p class="card-listing-course__club body--p1--regular">
          <router-link :to="club.to">
            {{ club.name }}
          </router-link>
        </p>
      </div>
      <h3 class="card-listing-course__course heading--h3">
        <component
          :is="course.to.course ? 'router-link' : 'span'"
          :to="course.to.course"
        >
          {{ course.name }}
        </component>
      </h3>
    </template>
    <template #footer>
      <div class="card-listing-course__footer">
        <div class="card-listing-course__details">
          <div
            v-if="course.holes"
            class="card-listing-course__detail card-listing-course__detail--holes"
          >
            <p class="card-listing-course__label body--p1--regular">Holes</p>
            <p class="card-listing-course__value heading--h3">
              {{ course.holes }}
            </p>
          </div>
          <div
            v-if="course.par"
            class="card-listing-course__detail card-listing-course__detail--par"
          >
            <p class="card-listing-course__label body--p1--regular">Par</p>
            <p class="card-listing-course__value heading--h3">
              {{ course.par }}
            </p>
          </div>
          <div
            v-if="course.price"
            class="card-listing-course__detail card-listing-course__detail--fee"
          >
            <p class="card-listing-course__label body--p1--regular">Price</p>
            <p class="card-listing-course__value heading--h3">
              {{ course.price }}
            </p>
          </div>
        </div>
        <div class="card-listing-course__actions">
          <router-link
            v-if="course.to.editCourse"
            :to="course.to.editCourse"
            class="card-listing-course__action"
          >
            <USButton type="primary" label="Edit Course Info" />
          </router-link>
          <!-- <router-link
            v-if="course.to.manageTeeTimes"
            :to="course.to.manageTeeTimes"
            class="card-listing-course__action"
          >
            <USButton type="primary" label="Manage Tee Times" />
          </router-link> -->
          <router-link
            v-if="course.to.teeTimes"
            :to="course.to.teeTimes"
            class="card-listing-course__action"
          >
            <USButton type="primary" label="View Details" />
          </router-link>
        </div>
      </div>
    </template>
  </CardListing>
</template>

<script lang="ts" setup>
import { computed, defineComponent, defineProps, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import CardListing from '@base/components/CardListing/CardListing.vue'
import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'
import USButton from '@base/components/USButton/USButton.vue'
import USRatingStars from '@base/components/USRatingStars/USRatingStars.vue'

import {
  CardListingCourseCourseDetails,
  CardListingClubDetails
} from '@base/_types/Card'

defineComponent({
  name: 'CardListingCourse',
  components: {
    CardListing,
    ImageWrapper,
    USButton,
    USRatingStars
  }
})

const props = defineProps({
  course: {
    type: Object as PropType<CardListingCourseCourseDetails>,
    required: true
  },
  club: {
    type: Object as PropType<CardListingClubDetails>,
    required: true
  }
})

const featuredImageProps = computed(() => {
  return {
    ...props.course.featuredImage,
    height: '100%',
    width: '100%',
    lazy: true,
    objectFit: 'cover'
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

$stars-diameter-mobile: 14px;
$stars-diameter-tablet: 20px;
$stars-diameter-desktop: 20px;

.card-listing-course {
  --stars-diameter: #{$stars-diameter-mobile};

  color: var(--font-color--primary-b2);

  @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
    --stars-diameter: #{$stars-diameter-tablet};
  }

  @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
    --stars-diameter: #{$stars-diameter-desktop};
  }

  &__featured-image {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &__heading {
    align-items: flex-start;
    display: flex;
    flex-flow: column wrap;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      align-items: center;
      flex-flow: row nowrap;
    }
  }

  &__rating {
    flex: 0 0 auto;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      flex: 0 0 auto;
      margin-left: var(--spacing--7);
      order: 2;
      width: auto;
    }
  }

  &__metadata {
    display: flex;
    flex: 1 1 70%;
    flex-flow: row nowrap;
    font-weight: var(--font-weight--medium);
    justify-content: flex-end;
    margin-top: var(--spacing--8);
    text-transform: uppercase;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      margin-top: 0;
      order: 3;
    }
  }

  &__metadata-item {
    margin-left: var(--spacing--7);

    &:first-child {
      margin-left: 0;
    }
  }

  &__status {
    color: var(--font-color--neutral-a3);
  }

  &__club {
    font-weight: var(--font-weight--medium);
    margin-top: var(--spacing--8);
    text-transform: uppercase;
    width: 100%;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      flex: 0 0 auto;
      margin-top: 0;
      order: 1;
      width: auto;
    }
  }

  &__course {
    color: var(--font-color--primary-b1);
    margin-top: var(--spacing--8);
    width: 100%;
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &__details {
    display: flex;
    flex-flow: row wrap;
  }

  &__detail {
    margin-bottom: var(--spacing--8);
    margin-right: var(--spacing--6);

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__label {
    font-weight: var(--font-weight--medium);
    margin-bottom: var(--spacing--8);
    text-transform: uppercase;
  }

  &__actions {
    display: flex;
    flex-flow: column nowrap;

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      align-items: flex-end;
      flex-flow: row nowrap;
    }
  }

  &__action {
    display: inline-block;
    margin-top: var(--spacing--6);

    @include breakpoint.min-width(breakpoint.$breakpoint--9) {
      margin-left: var(--spacing--6);
      margin-top: 0;
    }

    &:first-child {
      @include breakpoint.min-width(breakpoint.$breakpoint--9) {
        margin-left: 0;
      }
    }
  }
}
</style>
