<template>
  <CardImage
    :background="background"
    :foreground="foreground"
    class="card-hole"
  >
    <USButtonMenu
      v-if="showMenu"
      :button-diameter="6"
      :dropdown-menu-items="[{ label: 'Edit' }, { label: 'Delete' }]"
      dropdown-menu-alignment="right"
      icon-color="var(--card-font-color)"
      class="card-hole__dropdown"
      @clickOption="onClickOption"
    />
    <div class="card-hole__content">
      <h3 class="card-hole__heading heading--h1 margin-bottom--9">
        {{ title }}
      </h3>
      <span class="card-hole__par body--p1--regular text-transform--uppercase">
        {{`Par ${par}`}}
      </span>
    </div>
  </CardImage>
</template>

<script lang="ts" setup>
import { defineComponent, defineEmits, defineProps, PropType } from 'vue'

import CardImage from '@base/components/CardImage/CardImage.vue'
import USButtonMenu from '@base/components/USButtonMenu/USButtonMenu.vue'

import { CardImage as CardImageType } from '@base/_types/Card'

defineComponent({
  name: 'CardHole',
  components: { CardImage, USButtonMenu }
})

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  par: {
    type: Number,
    required: true
  },
  foreground: {
    type: Object as PropType<CardImageType>,
    required: true
  },
  background: {
    type: Object as PropType<CardImageType | undefined>,
    default: undefined
  },
  showMenu: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['edit', 'delete'])

const onClickOption = (e: string) => {
  switch (e) {
    case 'Edit':
      emit('edit')
      break
    case 'Delete':
      emit('delete')
      break
  }
}
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

$card-height-mobile: 280px;
$card-height-tablet: 350px;
$card-height-desktop: 480px;

.card-hole {
  --card-font-color: var(--font-color-neutral-0);

  color: var(--card-font-color);
  height: $card-height-mobile;
  transition: color var(--animation-speed-medium) var(--animation-curve-exit);

  @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
    height: $card-height-tablet;
  }

  @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
    height: $card-height-desktop;
  }

  &:hover {
    --card-font-color: var(--font-color--primary-b2);

    transition-timing-function: var(--animation-curve-enter);
  }

  :deep(.card-wrapper__card) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
  }

  &__dropdown {
    position: absolute;
    right: var(--spacing--6);
    top: var(--spacing--6);
  }

  &__content {
    padding: 0 var(--spacing--6) var(--spacing--6);
  }

  &__par {
    background-color: var(--color--secondary-c2);
    border-radius: var(--border-radius--small);
    color: var(--color--primary-b1);
    display: inline-block;
    padding: var(--spacing--8);
  }

  &__heading {
    margin-bottom: var(--spacing--8);
  }
}
</style>
